
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.burgerBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.drawer {
  top: 0;
  height: 100%;
  transform: translate(0) !important;
  :global(.ant-drawer-header) {
    padding: 0;
  }
  :global(.ant-drawer-close) {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 62px;
    position: absolute;
    right: 0;
  }
  :global(.ant-drawer-body) {
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
  :global(.ant-drawer-content-wrapper) {
    width: 360px !important;
    transform: translate(0) !important;
    @media #{$xs} {
      width: 100vw !important;
    }
  }
}
.titleBox {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 12px 50px 15px 40px;
  @media #{$xs} {
    padding: 12px 40px 12px 25px;
  }
}
.drawerBody {
  position: relative;
  padding: 12px 30px 100px 40px;
  height: 100%;
  @media #{$xs} {
    padding-left: 20px;
    padding-right: 20px;
  }
  :global(.ant-drawer-right.ant-drawer-open.no-mask) {
    transform: translateX(1px) !important;
  }
}
.mainWrapNone {
  display: none;
}
.mainWrap {
  display: block;
}
