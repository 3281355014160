
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
//HEADER
.wrap {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  position: relative;
  z-index: 20;
  width: 100vw;
}
.wrap-meta {
  width: 100%;
  background: $cUIwhite;
  border-bottom: 1px solid #dde4ea;
  @media #{$lg} {
    display: none;
  }
}
.content {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  height: 40px;
  @media #{$md} {
    height: 32px;
  }
  @media #{$sm} {
    height: 24px;
  }
}
