
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.wrap {
  background-color: $cUIwhite;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #e2e6f0;
  }
  @media #{$lg}{
    :global(.container) {
      max-width: 1365px;
    }
  }
}
.content {
  position: relative;
  display: grid;
  grid-template-columns: 20% 1fr;
  align-items: center;
  height: 80px;
  transition: all 0.45s ease-out 0s;
  z-index: 1;
  :global(.customDropdown) {
    z-index: 3;
    top: 80px !important;
    left: 15px !important;
    width: calc(100% - 30px);
    background: $cUIwhite;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 0;
    pointer-events: auto !important;
    :global(.ant-cascader-menu) {
      position: relative;
      z-index: 1;
      height: 100%;
      overflow: unset;
      padding: 26px 0 30px 0;
      border-radius: 0;
      &:first-child {
        background: $cUIlight;
        border: none;
        padding-bottom: 70px;
      }
      &:nth-child(2) {
        border-right: 1px solid $cUIdark;
        background-color: white;

        .ant-cascader-menu-item-active {
          background: #3467b4;
        }

        :global(.ant-cascader-menu-item-expand-icon) {
          transform: translate(0, -60%);
          top: 50%;
        }
      }
      &:nth-child(3) {
        position: relative;
        //display: grid;
        //grid-template-columns: 1fr 1fr 1fr;
        //grid-template-rows: repeat(10, 50px);
        //grid-auto-flow: column;
        //align-items: center;
        //padding-top: 5px;
        display: flex;
        flex-direction: column;
        max-width: 400px;
        min-width: 216px;
        border-right: 1px solid #dde4ea;
        margin-right: 8px;

        //&:after,
        //&:before {
        //  content: '';
        //  position: absolute;
        //  top: 12px;
        //  left: calc(100% / 3);
        //  bottom: 12px;
        //  width: 1px;
        //  height: calc(100% - 24px);
        //  background: $cUIdark;
        //}
        //&:before {
        //  left: calc(100% / 3 * 2);
        //}
        :global(.ant-cascader-menu-item) {
          height: auto;
          min-height: 32px;
        }

        :global(.ant-cascader-menu-item:hover),
        :global(.ant-cascader-menu-item-active) {
          background: transparent;

          &:hover {
            background: transparent;
          }
        }
      }
      :global(.ant-cascader-menu-item-active) {
        background: $cBrandPrimary;
        &:hover {
          background: $cBrandPrimary;
        }
        :global(.customCascaderList) {
          display: flex;
          align-items: center;
          padding: 6px 20px;
          min-height: 62px;
          @media #{$lg} {
            padding: 6px 10px;
          }
          p {
            color: $cUIwhite;
          }
          > img {
            display: none;
            visibility: hidden;
            &:first-child {
              display: block;
              visibility: unset;
            }
          }
        }
        :global(.ant-cascader-menu-item-expand-icon) svg {
          display: block;
        }
      }
    }
    :global(.ant-cascader-menu-item) {
      padding: 0;
      white-space: normal;
      line-height: 1;
      transition: all 0.3s ease-out 0s;

      &:hover {
        background: #e5e6e4;
      }

      :global(.customCascaderList) {
        display: flex;
        align-items: center;
        padding: 6px 20px;
        min-height: 62px;
        @media #{$lg} {
          padding: 6px 10px;
        }

        > img {
          &:first-child {
            display: none;
            visibility: hidden;
          }
        }
      }
    }
    :global(.ant-cascader-menu-item-expand-icon) {
      position: absolute;
      top: 15px;
      right: 28px;
      @media #{$lg} {
        top: 10px;
        right: 10px;
      }
    }
  }
}
.iconLogoWrap {
  display: flex;
  align-items: center;
  min-width: 202px;
  max-width: 250px;
  width: 250px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  cursor: pointer;
  img {
    aspect-ratio: 2.425;
  }
  @media #{$xs} {
    width: 136px;
    height: 55px;
  }
}
.menu {
  display: grid;
  grid-auto-flow: column;
  justify-items: end;
  height: 100%;
  @media #{$lg} {
    display: none;
  }
  :global(.header-menu__item) {
    display: flex;
    align-items: center;
    height: 100%;
    color: rgb(46, 46, 46);
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease-out 0s;
    white-space: nowrap;
    svg {
      display: block;
      width: 28px;
      height: 26px;
      object-fit: contain;
      padding-right: 8px;
      opacity: 0;
      transition: 0.3s ease-out;
    }
    &:hover {
      color: $cBrandPrimary;
      border-color: $cBrandPrimary;
      svg {
        opacity: 1;
      }
    }
    &:global(.is-active) {
      font-weight: 600;
      color: $cBrandPrimary;
      border-color: $cBrandPrimary;
      svg {
        opacity: 1;
      }
    }
    &:global(.activeDropdown) {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 10px 6px;
        border-color: transparent transparent $cBrandPrimary transparent;
        transition: 0.3s ease-out;
      }
    }
  }
}


.burgerMenu {
  margin: auto 0 auto auto;
  display: none;
  @media #{$lg} {
    display: flex;
  }
}
