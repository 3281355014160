
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.pickedName {
  text-transform: uppercase;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  color: $cUIblack;
  font-weight: 500;
  padding-left: 10px;
  transition: all 0.45s ease-out 0s;
}
.currencyItemWrap {
  padding: 10px 25px 10px 40px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  transition: all 0.45s ease-out 0s;
  .currency {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: $cUIblack;
    font-size: 16px;
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
  .itemName {
    font-size: 14px;
    display: block;
    color: $cUIblack;
  }
}
