
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.wrap {
  height: 22px;
  max-width: max-content;
  position: relative;
  cursor: pointer;
  z-index: 2;
  font-family: $font;
  &:hover {
    .is--list {
      display: block;
    }
  }
  @media #{md} {
    display: none;
  }
  @media #{sm} {
    margin-right: 0;
    &:first-child {
      margin-right: 17px;
    }
  }
}
.title {
  font-size: 13px;
  display: block;
  color: $white;
  @media #{$sm} {
    margin-right: 7px;
  }
}
.list {
  position: absolute;
  z-index: 3;
  top: 46px;
  right: 0;
  border-top: 4px solid $accent;
  background: $white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: right;
  color: $black;
}
.triangle {
  position: absolute;
  top: -15px;
  right: 15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 12px 8px;
  border-color: transparent transparent $accent transparent;
  transform: rotate(360deg);
}
.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.45s ease-out;
  &:hover {
    background-color: $lightGrey2;
    span {
      color: $cBrandPrimary;
    }
  }
  span {
    transition: 0.45s ease-out;
  }
}
.picked__wrap {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.picked__icon {
  width: 22px;
  height: 14px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
}
.span__currency {
  text-transform: uppercase;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  color: $cUIblack;
  font-weight: bold;
}
.picked__name {
  text-transform: uppercase;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  color: $cUIblack;
  font-weight: 500;
  padding-left: 10px;
}
.dropDownArrow {
  border-style: solid;
  border-color: rgb(46, 46, 46);
  border-image: initial;
  margin-left: 10px;
  border-width: 0px 1px 1px 0px;
  display: inline-block;
  padding: 3px;
  align-self: start;
  margin-top: 5px;
  cursor: pointer;
  transform: rotate(45deg);
}
