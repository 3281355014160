
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 43px;
}
.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  width: 0.5px;
  background-color: $cTextMuted;
  height: 24px;
}
