// colors

$cBrandPrimary: #3570c9;
$cBrandSecondary: #0d4fb2;
$cBrandMuted: #c9dfff;

$cTextPrimary: #2e2e2e;
$cTextSecondary: #8293aa;
$cTextMuted: #a5b3c5;

$cUIdark: #dde4ea;
$cUIlight: #f5f7fa;
$cUIwhite: #ffffff;
$cUIblack: #2e2e2e;
$cUIrecommended: #e88b48;
$cUIgreen: #90d285;
$cUIerror: #fb4b53;

// variables

$xs: #{'(max-width: 573px)'}; // 360 - 573
$sm: #{'(max-width: 767px)'}; // 574 -767
$mds: #{'(max-width: 960px)'}; // 960 - 767
$md: #{'(max-width: 1023px)'}; // 768 - 1023
$lg: #{'(max-width: 1365px)'}; // 1024 - 1365
$xl: #{'(min-width: 1920px)'}; // 1920...

$breakpoints: (
  'phone': 574px,
  'tablet': 1024px,
  'desktop': 1366px,
) !default;

@mixin media($type) {
  @media #{'(max-width: #{ map-get($breakpoints, $type) - 1}'} {
    @content;
  }
}

$grid-bottom: 4px;

$grid-xl-padding: 8 * $grid-bottom;
$grid-md-padding: 6 * $grid-bottom;
$grid-sm-padding: 4 * $grid-bottom;

// old variables
// Global variables
$font: 'Montserrat', sans-serif;
$fontLight: 300;
$fontRegular: 400;
$fontSemiBold: 500;
$fontBold: 700;
$fontExtraBold: 800;
$green: #90d285;
$darkGreen: #43de6f;
$blue: #3867d6;
$blueHover: #3c6cff;
// newsList page
$blueTitle: #3570c9;
// Admin/CompanyPage
$blueTitleCompanyPage: #3570c9;
// Admin/Profile
$skyblue: #2b61b4;
$skyblue2: #69bffd;
$skyblueHover: #0387c5;
$red: #fb4b53;
$darkRed: #bf3631;
$white: #ffffff;
$whiteLight: #ffffff;
$grey: #a5b3c5;
$greyHover: #92a3b9;
$darkgrey: #3e5064;
$lightGrey: #8796a6;
$lightBorder: #e7ecf1;
$lightHover: #eff4f9;
$violet: #404764;
$dark: #1b1d2a;
$lightDark: #232b36;
$warning: #fce095;
$primaryLight: rgba(0, 134, 84, 0.08);
$lightWhite: #88919a;
$transition: 0.25s;
$lightGrey2: #e8eaf0;
$mStep: 5;
$pStep: 5;
$mwStep: 50;
$purple: #f1f4fe;
$lightPurple: #aeaeae;
$lightBrown: #f3f3f3;
$greyBorder: #e0e4ef;
$lightGreen: #e3f3e2;
$lightBlue: #4173cd;
$darkBlue: #0363d4;
$darkGrey2: #6a6d6f;
// ----
$background: #ffffff;
$secondaryBG: #f5f7fa;
$border: #dde4ea;
$disabled: #dde4ea;
$accent: #3570c9;
$accentHover: #0d4fb2;
$accentActive: #00317b;
$black: #2e2e2e;
$azure: #0051b1;
$field: #f5f7fa;
$lightGreyText: #8293aa;
// ---
$titleText: #2c3d46;
$titleTextDescr: #111111;
