
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.footer {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
}
.info {
  display: grid;
  background: #212c38;
}
.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 80px 0 100px;
  gap: 50px;
  justify-content: space-between;
}
.content_top__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
}
.content_top__menu_box {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  gap: 30px;
}
.box {
  display: grid;
  grid-row-gap: 24px;
  align-self: flex-start;
}
.box-info {
  display: grid;
  grid-row-gap: 24px;
  justify-self: flex-end;
  & p {
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    color: #ffffff;
    padding-bottom: 24px;
  }
  &__flag{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    max-width: 460px;
    @media (max-width: 1365px){
      grid-column: 1 / 4;
    }
    @media (max-width: 573px){
      grid-column: 1;
    }
    .flag{
      width: 120px;
      min-width: 120px;
      height: 120px;
      margin-right: 32px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 573px){
        margin-right: 22px;
      }
      img{
        width: 98px;
        height: 98px;
      }
    }
    .text{
      .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        margin-bottom: 18px;
        padding-bottom: 0;
        @media (max-width: 573px){
          font-size: 14px;
        }
      }
      a{
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #FFFFFF;
        position: relative;
        span{
          position: absolute;
          width: 6px;
          height: 6px;
          transform: rotate(45deg);
          top: 5px;
          right: -12px;
          border-right: 2px solid #fff;
          border-top: 2px solid #fff;
        }
      }
    }
  }
}
.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #ffffff;
}
.linkBox {
  display: grid;
  grid-row-gap: 16px;
  align-items: flex-start;
  & a {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    transition: transform 0.26s ease;
    &:hover {
      color: #3570c9;
      .arrow {
        border: solid #3570c9;
        border-width: 0 2px 2px 0;
      }
    }
  }
  & a.link-policy {
    font-size: 14px;
  }
}
.linksMail {
  display: flex;
  flex-direction: row;
  & a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    transition: transform 0.26s ease;
    background: #37414b;
    border-radius: 2px;
    padding: 12px 24px;
    margin-right: 8px;
    &::before {
      content: '';
      width: 22px;
      height: 14px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 2px;
      background-image: url('/img/iconsSvg/footer/mail.svg');
    }
  }
}
.linksSocial {
  display: flex;
  flex-wrap: wrap;
  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    transition: transform 0.26s ease;
    background: #37414b;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    &:hover {
      color: #3570c9;
      .arrow {
        border: solid #3570c9;
        border-width: 0 2px 2px 0;
      }
    }
  }
}
.singleIcon {
  margin-right: 8px;
  margin-bottom: 14px;
  &:last-child {
    margin-right: 0;
  }
}
.arrow {
  border: solid #ffffff;
  margin-left: 3px;
  margin-bottom: 2px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  align-self: start;
  cursor: pointer;
  transform: rotate(315deg);
}
.bottomInfo {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  background: #212c38;
  margin-top: -1px;
}
.line {
  width: 100%;
  opacity: 0.2;
}
.bottomInfo__box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.logo {
  display: inline-block;
}
.copyright {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  line-height: 40px;
  & .copy_box span {
    opacity: 0.5;
  }
}
.nav-lang {
  display: inline-flex;
  gap: 2px;
  padding-left: 2px;
  padding-right: 16px;
  & a {
    opacity: 0.5;
    width: 30px;
    &:last-child{
      text-align: left;
    }
    &:hover {
      opacity: 1;
    }
  }
}
.blockLinks {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  a:first-child {
    padding-right: 17px;
  }
}

.partner{
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: flex-start;
  gap: 12px;
  span{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -32px;
    @media #{$lg}{
      margin-top: 4px;
    }
  }
}

.сommunication__box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 54px;
}

.сommunication__item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  border: 1px solid #ffffff;
  padding: 12px 20px;
  flex: auto;
}

.сommunication__item img {
  width: 40px;
  height: 40px;
}

.сommunication__item span {
  color: #ffffff;
}

//Media
@media #{$xl} {
  .content {
    padding-bottom: 60px;
  }
}
@media #{$lg} {
  .box {
    grid-column-gap: 30px;
  }
  .box-info {
    grid-column-gap: 30px;
    grid-column-start: 1;
    grid-column-end: 2;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  .linksMail {
    display: inline-block;
    flex-direction: column;
    & a {
      margin-bottom: 16px;
    }
  }
  .bottomInfo__box {
    flex-direction: column;
    align-items: flex-start;
  }
  .partner{
    padding: 30px 0;
  }
}
@media (max-width: 1023px) {
  .сommunication__box {
    flex-direction: column;
    gap: 24px;
    max-width: 400px;
  }

  .сommunication__item {
    font-size: 14px;
    justify-content: flex-start;
    padding: 12px 24px;
    width: 100%;
  }

  .content_top__menu_box {
    grid-template-columns: repeat(3, 200px);
  }
  .content {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}
@media (max-width: 768px) {
  .box-info {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .copy_box {
    margin-bottom: 16px;
  }
  .copyright {
    flex-direction: column;
    align-items: flex-start;
  }
  .content_top__menu_box {
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  }
}
@media #{$sm} {
  .box-info {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media #{$xs} {
  .content {
    grid-template-columns: repeat(1, 1fr);
    padding: 80px 0 64px;
  }
  .box,
  .box-info {
    grid-template-columns: 1fr;
    grid-column-start: auto;
    grid-column-end: auto;
  }
  .linkBox {
    grid-row-gap: 14px;
  }
  .blockLinks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & a {
      margin-bottom: 14px;
      font-size: 12px;
    }
  }
  .logo {
    display: inline-block;
    margin-bottom: 10px;
  }
  .copy_box {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: normal;
  }
  .content_top__menu_box {
    grid-template-columns: 1fr;
  }
  .сommunication__box {
    max-width: 100%;
  }
}
