
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.2s;
  user-select: none;
  outline: none;
  appearance: none !important;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &:global(.disabled){
    background-color: $disabled;
    border-color: $disabled;
    color: $grey;
    cursor: not-allowed;
  }
  &:global(.secondary){
    color: $accent;
    background-color: transparent;
    border: 1px solid $accent;
    outline: none;
    &:hover:not(:disabled) {
      color: $white;
      background-color: $accent;
    }
    &:active:not(:disabled) {
      color: $white;
      background-color: $accentActive;
      outline: none;
      border-color: $accentActive;
    }
    &:focus:not(:disabled) {
      color: $white;
      background-color: $accent;
      outline: 1px solid $accent;
      border-color: $white;
    }
  }
  &:global(.grey){
    color: $grey;
    background-color: transparent;
    border: 1px solid $grey;
    font-weight: 700;
    font-size: 11px;
    &:hover:not(:disabled) {
      color: $white;
      background-color: $grey;
    }
    &:active:not(:disabled) {
      color: $white;
      background-color: $lightGrey;
      border-color: $lightGrey;
    }
    &:focus:not(:disabled) {
      color: $white;
      background-color: $grey;
      outline: 1px solid $grey;
      border-color: $white;
    }
  }
  &:global(.success){
    color: $white;
    background-color: $green;
    border: 1px solid $green;
    outline: none;
    &:hover:not(:disabled) {
      color: $white;
      background-color: $darkGreen;
    }
    &:active:not(:disabled) {
      color: $white;
      background-color: $darkGreen;
      outline: none;
      border-color: $green;
    }
    &:focus:not(:disabled) {
      color: $white;
      background-color: $darkGreen;
      outline: 1px solid $darkGreen;
      border-color: $white;
    }
  }
  &:global(.danger){
    color: $white;
    background-color: $red;
    border: 1px solid $red;
    &:hover:not(:disabled) {
      color: $white;
      background-color: $darkRed;
    }
    &:active:not(:disabled) {
      color: $white;
      background-color: $darkRed;
      border-color: $red;
    }
    &:focus:not(:disabled) {
      color: $white;
      background-color: $darkRed;
      outline: 1px solid $darkRed;
      border-color: $white;
    }
  }
  &:global(.delete){
    color: $red;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0;
    &:hover:not(:disabled) {
      color: $darkRed;
    }
    &:before {
      content: "×";
      color: $red;
      display: inline-block;
      font-size: 30px;
      font-weight: 100;
      margin-right: 2px;
    }
  }
  &:global(.tertiary){
    font-weight: 600;
    outline: none;
    background-color: $field;
    border: none;
    color: $accent;
    &:hover:not(:disabled) {
      border-color: $border;
    }
    &:active:not(:disabled) {
      background-color:  $border;
      border: none;
    }
    &:focus:not(:disabled) {
      background-color: $border;
      outline: 1px solid $border;
      border: none;
    }
  }
  &:global(.dashed){
    border: 1px dashed $accent;
    box-sizing: border-box;
    border-radius: 1px;
    font-weight: 600;
    color: $accent;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    &:hover:not(:disabled) {
      background: $secondaryBG;
      border: 1px dashed $accent;
    };
    &:active:not(:disabled) {
      background: $disabled;
      border: 1px dashed $accent;
    };
    &:focus:not(:disabled) {
      background: $secondaryBG;
      border: 1px dashed $accent;
    };
  }
  &:global(.primary){
    color: $white;
    background-color: $accent;
    border: 1px solid $accent;
    &:hover:not(:disabled) {
      color: $white;
      background-color: $accentHover;
      border-color: $accentHover;
    }
    &:active:not(:disabled) {
      color: $white;
      background-color: $accentActive;
      border-color: $accentActive;
    }
    &:focus:not(:disabled) {
      color: $white;
      background-color: $accent;
      outline: 1px solid $accent;
      border-color: $white;
    }
  }
  &:global(.arrowBtn){
    color: $white;
    background-color: #56B845;
    border: 1px solid #56B845;
    position: relative;
    width: calc(100% - 13px);
    margin-left: 13px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    height: 46px;
    &:before {
      content: "";
      position: absolute;
      flex: 0 0 auto;
      width: 27px;
      height: 27px;
      inset: 0 auto 0 -13px;
      margin: auto;
      border-radius: 50% 50%;
      background: #3570C9 url('/img/arrow/arrow-short.svg') no-repeat center center;
      background-size: 15px auto;
    }
    &:hover {
      color: $white;
      background-color: #56B845;
      border-color: #56B845;
    }
    &:active {
      color: $white;
      background-color: #56B845;
      border-color: #56B845;
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

  }
}
