
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
//NotAuthorized:
.wrap {
  display: flex;
  align-items: center;
  @media #{$md} {
    display: none;
  }
}
.userAvatar{}
.metaAuthBlock {
  display: flex;
  align-items: center;
  .signIn {
    font-size: 14px;
    font-weight: bold;
    color: $cUIwhite;
    padding: 3px 17px;
    border: 1px solid $cBrandPrimary;
    background: $cBrandPrimary;
    border-radius: 1px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.45s ease-out;

    &:hover {
      background-color: $accentHover;
      border: 1px solid $accentHover;
    }
  }
  .userName {
    font-weight: bold;
    color: $cUIblack;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}
.messageAlert {
  position: absolute;
  bottom: 23px;
  right: 32px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 50%;
  background-color: $cUIgreen;
  z-index: 3;
  &.blue {
    background-color: $cBrandPrimary;
  }
  &.green {
    background-color: $cUIgreen;
  }
  &:after {
    font-weight: normal;
    content: '!';
    color: $cUIwhite;
  }
}
.signUpWrapper {
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 10px;
  margin-left: 26px;
  .signUpRegistration {
    display: grid;
    grid-template-columns: auto 6px;
    grid-column-gap: 10px;
    .dropDownArrowNotAuthorized {
      border: solid $cUIblack;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      align-self: start;
      margin-top: 6px;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }
  .signUpButton {
    font-size: 14px;
    font-weight: bold;
    color: $cUIblack;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
}
.signUpOptions {
  position: absolute;
  z-index: 3;
  grid-template-columns: 180px 180px;
  grid-column-gap: 20px;
  justify-content: center;
  align-content: center;
  border-top: 4px solid $cBrandPrimary;
  background: $cUIwhite;
  top: 50px;
  right: -20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  display: grid;
  width: 460px;
  height: 125px;
  @media #{$xs} {
    right: -5px;
  }
  .signUpBtn {
    background: transparent;
    border: none;
    padding: 0;
    a {
      color: $cUIwhite;
      height: 44px;
      background: $cBrandPrimary;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      display: grid;
      justify-content: center;
      align-content: center;
      transition: 0.45s ease-out;
      &:hover {
        background: $accentHover;
      }
    }
  }
}

//Authorized:
.userWrap {
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  @media #{$md} {
    display: none;
  }

  .imgBox {
    border-radius: 50%;
    box-shadow: 0 0 0 2px $skyblue;
    height: 24px;
    width: 24px;
    overflow: hidden;
  }
  
  .signOutWrapper {
    display: grid;
    height: 22px;
    align-self: center;
    margin-left: 10px;
    .dropDownArrowAuthorized {
      border: solid $cUIblack;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      align-self: start;
      margin-top: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      cursor: pointer;
    }
  }
  .signOutOptions {
    position: absolute;
    z-index: 3;
    padding: 15px 20px;
    top: 55px;
    right: 5px;
    min-width: 245px;
    min-height: auto;
    justify-content: end;
    align-content: center;
    border-top: 4px solid $cBrandPrimary;
    background: $cUIwhite;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    display: grid;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: right;
    color: $cUIblack;
    grid-template-columns: 1fr;

    span,
    a {
      cursor: pointer;
      grid-column-start: 1;
      transition: 0.45s ease-out;
      :hover {
        color: $cBrandPrimary;
      }
    }
    .titleWrap {
      display: flex;
      padding: 10px 0;
      border-top: 1px dashed #eceff6;
      background-color: transparent;
      border: transparent;
      cursor: pointer;
      &:hover {
        font-weight: bold;
        color: rgb(53, 112, 201);
      }
      .itemImg {
        display: flex;
        justify-content: center;
        min-width: 20px;
        margin-right: 10px;
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        white-space: nowrap;
        font-size: 12px;
      }
    }
  }
}

.filledTriangle {
  position: absolute;
  top: -15px;
  right: 15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 12px 8px;
  border-color: transparent transparent $cBrandPrimary transparent;
  -webkit-transform: rotate(360deg);
}
