
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.banner{
  background: url("/img/banners/new-year/bg.webp") repeat center center;
  background-size: cover;
  z-index: 21;
  position: relative;
  :global(.no-webp) &{
    background: url("/img/banners/new-year/bg.jpg") repeat center center;
  }
}
.wrap{
  height: 93px;
  position: relative;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 915px;
  padding-left: 54px;
  padding-right: 194px;
  margin: auto;
  @media #{$md}{
    padding-left: 54px;
    padding-right: 54px;
  }
  @media #{$sm}{
    height: 67px;
    padding-left: 20px;
    padding-right: 40px;
  }
  &:before{
    content: "";
    display: block;
    background: url("/img/banners/new-year/icon-1.png") no-repeat center center;
    width: 57px;
    height: 116px;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-size: cover;
    @media #{$md}{
      right: 6px;
      left: auto;
    }
    @media #{$sm}{
      right: 16px;
      width: 38.44px;
      height: 80px;
    }
  }
  &:after{
    content: "";
    display: block;
    background: url("/img/banners/new-year/icon-2.png") no-repeat center center;
    width: 209px;
    height: 97px;
    position: absolute;
    right: 0;
    bottom: -5px;
    @media #{$md}{
      display: none;
    }
  }
  > div{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #112032;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    > strong{
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      color: #112032;
      span{
        white-space: nowrap;
      }
      @media #{$sm}{
        font-size: 14px;
        text-align: left;
      }
    }
    > span{
      @media #{$md}{
        display: none;
      }
    }
  }
}

