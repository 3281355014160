
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.pickedIcon {
  width: 22px;
  height: 14px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
}
.pickedName {
  text-transform: uppercase;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  color: $cUIblack;
  font-weight: 500;
  padding-left: 10px;
  transition: all 0.45s ease-out 0s;
}
.langLink {
  padding: 10px 25px 10px 40px;
  display: flex;
  align-items: center;
  transition: all 0.45s ease-out 0s;
  .icon {
    min-width: 13px;
    max-width: 13px;
    flex: 0 1 13px;
    width: 13px;
    height: 10px;
    min-height: 10px;
    max-height: 10px;
    margin-right: 10px;
  }
  .itemName {
    font-size: 14px;
    display: block;
    color: $cUIblack;
  }
}
